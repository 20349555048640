import { Injectable } from '@angular/core';
import { createStore, setProps, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

interface StartSectionProps {
  firstVideo: string;
  countQuestions: number;
  rewatchVideo: boolean;
  showVideoExam: boolean;
  disableExamRetake: boolean;
}

const name = 'startSection';
const store = createStore(
  {name},
  withProps<StartSectionProps>({
    firstVideo: '',
    countQuestions: 0,
    rewatchVideo: false,
    showVideoExam: false,
    disableExamRetake: false,
  })
);


@Injectable({
  providedIn: 'root',
})
export class ExamState {
  readonly state$ = store.pipe(
    filter((state) => !!state)
  ) as Observable<StartSectionProps>;

  constructor() {}

  get snapshot(): StartSectionProps {
    return store.getValue();
  }

  reset(): void {
    store.reset();
  }

  patch(value: Partial<StartSectionProps>): void {
    store.update(setProps(value));
  }

  get getCountQuestions(): number {
    if (this.snapshot.countQuestions === 0) {
      return Number(localStorage.getItem('countQuestions'));
    }
    else {
      return this.snapshot.countQuestions;
    }
  }

  updateCountQuestions(countQuestions: number): void {
    localStorage.setItem('countQuestions', countQuestions.toString());
    this.patch({countQuestions: countQuestions});
  }
}
