import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { pages } from 'libraries/college-pages';
import { ModalRef } from '@airy-ui/modal';
import { ExamState } from '@pages/course/shared/state/exam-state.state';

@Component({
  selector: 'yeda-modal-wrong-answer',
  templateUrl: './modal-wrong-answer.component.html',
  styleUrls: ['./modal-wrong-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalWrongAnswerComponent implements OnInit {
  constructor(
    private modalRef: ModalRef,
    private router: Router,
    private startSectionState: ExamState,
  ) {}

  ngOnInit(): void {}

  notNow(): void {
    this.router.navigate([pages.myStudies.courses]).then();
    this.modalRef.close();
  }

  watchVideo(): void {
    this.router.navigate([this.startSectionState.snapshot.firstVideo]);
    this.modalRef.close();
  }
}
